import type { QueryResult } from '@apollo/client';
import ContentWorkflowQuery from '@aurora/shared-client/components/contentWorkflow/ContentWorkflow.query.graphql';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  ContentWorkflowPropertiesQuery,
  ContentWorkflowPropertiesQueryVariables,
  ContextMessageFragment,
  ContextNodeFragment
} from '@aurora/shared-generated/types/graphql-types';
import { AccessFunctionType } from '@aurora/shared-types/redirects/pageRedirect';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import type { ContextMessageQuery, ContextMessageQueryVariables } from '../types/graphql-types';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import { ContentWorkflowState } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Returns the content workflow query result to determine whether the user can edit a message with cwa enabled
 *
 * @param skip whether the skip the query
 * @param contextNodeId the id of the context nod
 */
function useContentWorkflowEditPageAccess(
  skip: boolean,
  contextNodeId: string
): QueryResult<ContentWorkflowPropertiesQuery, ContentWorkflowPropertiesQueryVariables> {
  // content workflow properties are checked for edit message pages with content workflow enabled
  return useQueryWithTracing<
    ContentWorkflowPropertiesQuery,
    ContentWorkflowPropertiesQueryVariables
  >(module, ContentWorkflowQuery, {
    variables: { id: contextNodeId },
    skip: skip
  });
}

/**
 * Determines whether the user can access the edit page for a message
 *
 * @param accessFunctionType the access function type
 * @param contextNode the context node
 * @param contextMessageQueryResult the context message query result from the AppContextProvider
 */
export default function useEditPageAccess(
  accessFunctionType: AccessFunctionType,
  contextNode: ContextNodeFragment,
  contextMessageQueryResult: Pick<
    QueryResult<ContextMessageQuery, ContextMessageQueryVariables>,
    'loading' | 'data'
  >
): { isEditPageAccessReady: boolean; canAccessEditPage: boolean } {
  const isEditPage: boolean = accessFunctionType === AccessFunctionType.EDIT_PAGE;
  const isCwaSupportedEditPage: boolean =
    accessFunctionType === AccessFunctionType.CONTENT_WORKFLOW_EDIT_PAGE;
  const [messageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);

  const { loading: contextMessageQueryLoading, data: contextMessageQueryData } =
    contextMessageQueryResult;
  const isContextMessageLoading: boolean = !contextMessageQueryData && contextMessageQueryLoading;
  const contextMessage: ContextMessageFragment = contextMessageQueryData?.message;

  const editPageQueryResult = useMessagePolicies(
    module,
    {
      id: contextMessage?.id,
      useCanEdit: true
    },
    !contextMessage || (!isEditPage && !isCwaSupportedEditPage)
  );

  const cwaEditPageQueryResult = useContentWorkflowEditPageAccess(
    !contextNode || !isCwaSupportedEditPage,
    contextNode?.id
  );

  const { data: editQueryData, loading: editQueryLoading } = editPageQueryResult;
  const { data: cwaEditQueryData, loading: cwaEditQueryLoading } = cwaEditPageQueryResult;

  const canRegularEdit: boolean = checkPolicy(editQueryData?.message?.messagePolicies?.canEdit);
  const cwaEnabled: boolean = checkPolicy(cwaEditQueryData?.coreNode?.cwaProperties?.cwaEnabled);

  if (isEditPage) {
    return {
      isEditPageAccessReady: !!contextMessage && !editQueryLoading && !!editPageQueryResult.data,
      canAccessEditPage: canRegularEdit
    };
  } else if (isCwaSupportedEditPage) {
    /*
     * Check edit permission when messageEditingState is null (i.e., when the user accesses the URL directly)
     * and when ContentWorkflowState is not `in_approval`,
     * since the edit CTA won't be visible in the UI if it's in the awaiting publication state.
     */
    const canUserCwaEdit =
      !!contextMessage &&
      'contentWorkflow' in contextMessage &&
      (messageEditingState === null ||
      contextMessage.contentWorkflow.state !== ContentWorkflowState.InApproval
        ? contextMessage.contentWorkflow.userContext.canEdit
        : true);
    return {
      isEditPageAccessReady:
        !isContextMessageLoading && !!contextMessage && !editQueryLoading && !cwaEditQueryLoading,
      canAccessEditPage: cwaEnabled ? canUserCwaEdit : canRegularEdit
    };
  }

  return {
    isEditPageAccessReady: true,
    canAccessEditPage: false
  };
}
