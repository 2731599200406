import type { QueryResult } from '@apollo/client';
import type {
  MessagePoliciesQuery,
  MessagePoliciesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { Optional } from '@aurora/shared-types/community';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import AppContext from '../context/AppContext/AppContext';
import useQueryWithTracing from '../useQueryWithTracing';
import messagePoliciesQuery from './MessagePolicies.query.graphql';

const log = getLog(module);

/**
 * Gets message-specific policies for a message.
 *
 * @param module The module
 * @param variables any message policies query variables.
 * @param skip whether to skip the graphql call.
 * @param ssr fetch this data during the server render
 * @param useCache whether to use the 'cache-first' fetch policy.  If false, 'cache-and-network' will be used.
 * @author Patricio Poratto
 */
export default function useMessagePolicies(
  module: NodeModule | string,
  variables: Optional<MessagePoliciesQueryVariables, 'id'>,
  skip = false,
  ssr = false,
  useCache = true
): QueryResult<MessagePoliciesQuery, MessagePoliciesQueryVariables> {
  const { contextMessage } = useContext(AppContext);
  const modifiedVariables = { id: contextMessage?.id, ...variables };
  const result = useQueryWithTracing<MessagePoliciesQuery, MessagePoliciesQueryVariables>(
    module,
    messagePoliciesQuery,
    {
      variables: modifiedVariables,
      fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
      skip: skip || IdConverter.isOptimistic(modifiedVariables.id),
      ssr
    }
  );
  if (result?.error) {
    log.error(result.error, 'Error getting message policies for %O', modifiedVariables);
  }
  return result;
}
