import type { QueryResult } from '@apollo/client';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  BlogTopicMessage,
  Maybe,
  Message,
  Revision,
  RevisionConnection,
  RevisionConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import type {
  ContextMessageFragment,
  MessageRevisionsQuery,
  MessageRevisionsQueryVariables,
  MessageViewFragment
} from '../../types/graphql-types';
import messageRevisionsQuery from './MessageRevisions.query.graphql';

const log = getLog(module);

/**
 * Checks to see if the preview mode flag is set or the current page is Manage Content.
 */
function useIsPreviewMode(): boolean {
  const { router } = useEndUserRoutes();
  return router.getUnwrappedQueryParam(EndUserQueryParams.PREVIEW_MESSAGE) === 'true';
}

/**
 * Checks to see if a specific revision is provided to restore history of a message.
 */
function useSpecificRevision(): string {
  const { router } = useEndUserRoutes();
  return router.getUnwrappedQueryParam(EndUserQueryParams.REVISION_ID);
}

/**
 * Get the first revision in the supplied connection
 * @param revisions
 */
function getFirstRevision(revisions: RevisionConnection): Maybe<Revision> | undefined {
  return revisions?.edges?.[0]?.node;
}

/**
 * Get the message from the revision. This merges any versioned data from the Revision object into the returned message,
 * since the referenced message may come from cache and have data for a different version.
 *
 * If the currentPublishedMessage is given, takes the meta-data from this message object instead of the revision's
 * message object and merges it with the latest version's editable data from the revision object.
 *
 * @param revision
 * @param currentPublishedMessage
 */
function getRevisionMessage(
  revision: Revision,
  currentPublishedMessage = null
): Message | BlogTopicMessage {
  if (!currentPublishedMessage && !revision.message) {
    throw new Error(
      'Revision is missing a message.  Ensure the GraphQL query is selecting for the revision message.'
    );
  }
  const { message: revisionMessage } = revision;
  const message = currentPublishedMessage ?? revisionMessage;
  const {
    revisionNum,
    body,
    subject,
    rawBody,
    introduction,
    teaser,
    rawTeaser,
    coverImageProperties,
    attachments
  } = revision;
  if (coverImageProperties === undefined) {
    // merge versionable data into message data
    return {
      ...(message as Message),
      revisionNum,
      subject,
      body,
      rawBody,
      introduction,
      teaser,
      rawTeaser,
      attachments
    };
  }
  // merge versionable data into message data
  return {
    ...(message as BlogTopicMessage),
    revisionNum,
    subject,
    body,
    rawBody,
    introduction,
    teaser,
    rawTeaser,
    coverImageProperties,
    attachments
  };
}

/**
 *
 * @param variables query varibles
 * @param skip to skip the query
 * @param revisionConstraints optional contstraints
 * @returns MessageRevisionsQuery
 */
function useMessageRevisionsQuery(
  variables: MessageRevisionsQueryVariables,
  skip = false,
  revisionConstraints?: RevisionConstraints
): QueryResult<MessageRevisionsQuery> {
  const isPreviewMode = useIsPreviewMode();
  const constraints: RevisionConstraints = revisionConstraints ?? {
    isPublished: {
      eq: !isPreviewMode
    }
  };
  return useQueryWithTracing<MessageRevisionsQuery, MessageRevisionsQueryVariables>(
    module,
    messageRevisionsQuery,
    {
      variables: {
        ...variables,
        constraints
      },
      skip: !variables?.id || skip
    }
  );
}

/**
 * A hook to get the specified message, or, if in preview mode, the latest revision of the specified message.
 * @param message the message to return if condition is not satisfied.
 * @param useLatestRevision if specified, use the latest revision, otherwise return the provided message.
 * @param queryVariables if specified, additional variables for the MessageRevisionsQuery.
 * If this argument is not provided, the default behavior is to provide the preview message only in preview mode where
 * the current user can edit.
 */
function useCurrentOrPreviewMessage(
  message: MessageViewFragment | ContextMessageFragment,
  useLatestRevision = false,
  queryVariables?: MessageRevisionsQueryVariables
): { message: ContextMessageFragment | MessageViewFragment | null; loading: boolean } {
  const isPreviewMode = useIsPreviewMode();
  const { isAnonymous } = useRegistrationStatus();

  const queryForRevisions =
    message?.id &&
    !IdConverter.isOptimistic(message?.id) &&
    !isAnonymous &&
    (useLatestRevision === true || isPreviewMode);

  const variables = {
    id: message?.id,
    ...queryVariables,
    revisionsFirst: 1,
    useRevisionMessage: true,
    useContributors: true
  };
  const { loading, data, error } = useMessageRevisionsQuery(variables, !queryForRevisions);
  if (queryForRevisions) {
    if (error) {
      log.error(error, 'Error querying for revisions with variables: %O', variables);
    } else if (loading || !data) {
      return { message: null, loading };
    } else {
      return {
        message: getRevisionMessage(
          getFirstRevision(data?.message?.revisions as RevisionConnection)
        ),
        loading
      };
    }
  }
  return { message, loading };
}

export {
  useIsPreviewMode,
  useMessageRevisionsQuery,
  getFirstRevision,
  useCurrentOrPreviewMessage,
  useSpecificRevision,
  getRevisionMessage
};
