import type { DocumentNode, QueryHookOptions, QueryResult } from '@apollo/client';
import nodeQuery from '@aurora/shared-client/components/context/ContextNode.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  BoardPagesAndParams,
  CategoryPageAndParams,
  GroupHubPageAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Scalars } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserPages,
  EndUserPathParams,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { getLog } from '@aurora/shared-utils/log';
import messageQuery from './ContextMessage.query.graphql';
import replyMessageQuery from './ContextMessageByReply.query.graphql';
import userQuery from './ContextUser.query.graphql';

const log = getLog(module);

/**
 * URL Objects - useObjectFromUrl, routes
 */

export interface UrlObjectType {
  query: DocumentNode;

  urlParamKey?: EndUserPathParams;

  urlParamKeyResolver?(router): string;

  type: string;
}

/**
 * Get the node id off the path.
 * @param router
 */
export function getNodeIdFromUrl(router: EndUserRouter): string | null {
  const boardId = router.getPathParam<BoardPagesAndParams>(EndUserPathParams.BOARD_ID);
  if (boardId) {
    return `board:${boardId}`;
  }
  const categoryId = router.getPathParam<CategoryPageAndParams>(EndUserPathParams.CATEGORY_ID);
  if (categoryId) {
    return `category:${categoryId}`;
  }
  const grouphubId = router.getPathParam<GroupHubPageAndParams>(EndUserPathParams.GROUPHUB_ID);
  if (grouphubId) {
    return `grouphub:${grouphubId}`;
  }
  return null;
}

export enum UrlObject {
  USER = 'user',
  MESSAGE = 'message',
  REPLY = 'reply',
  BOARD = 'board',
  CATEGORY = 'category',
  GROUPHUB = 'grouphub',
  NODE = 'node'
}

const UrlQueryObjectMap: Record<UrlObject, EndUserQueryParams> = {
  [UrlObject.BOARD]: undefined,
  [UrlObject.CATEGORY]: undefined,
  [UrlObject.GROUPHUB]: undefined,
  [UrlObject.MESSAGE]: EndUserQueryParams.MESSAGE_ID,
  [UrlObject.NODE]: undefined,
  [UrlObject.REPLY]: undefined,
  [UrlObject.USER]: undefined
};

const UrlObjectMap: Record<UrlObject, UrlObjectType> = {
  [UrlObject.USER]: { query: userQuery, urlParamKey: EndUserPathParams.USER_ID, type: 'user' },
  [UrlObject.MESSAGE]: {
    query: messageQuery,
    urlParamKey: EndUserPathParams.MESSAGE_ID,
    type: 'message'
  },
  [UrlObject.REPLY]: {
    query: replyMessageQuery,
    urlParamKey: EndUserPathParams.REPLY_ID,
    type: 'message'
  },
  [UrlObject.BOARD]: {
    query: nodeQuery,
    urlParamKey: EndUserPathParams.BOARD_ID,
    type: NodeType.BOARD
  },
  [UrlObject.CATEGORY]: {
    query: nodeQuery,
    urlParamKey: EndUserPathParams.CATEGORY_ID,
    type: NodeType.CATEGORY
  },
  [UrlObject.GROUPHUB]: {
    query: nodeQuery,
    urlParamKey: EndUserPathParams.GROUPHUB_ID,
    type: NodeType.GROUPHUB
  },
  [UrlObject.NODE]: { query: nodeQuery, urlParamKeyResolver: getNodeIdFromUrl, type: null }
};

/**
 * Represents an object query that requires an ID property for lookup.
 */
interface IdBasedQuery {
  /**
   * The id of the primary element to query.
   */
  id: Scalars['ID']['input'];
}

function useObjectKeyFromUrl<
  RouteType extends EndUserPages,
  RouteAndParams extends BaseRouteAndParams<RouteType>
>(urlObject: UrlObject): string {
  const { router } = useEndUserRoutes();
  const { urlParamKey, urlParamKeyResolver } = UrlObjectMap[urlObject];
  if (urlParamKey) {
    return router.getPathParam<RouteAndParams>(urlParamKey as keyof RouteAndParams['params']);
  } else if (urlParamKeyResolver) {
    return urlParamKeyResolver(router);
  }
  return null;
}

function useQueryObjectKeyFromUrl(urlObject: UrlObject): string {
  const { router } = useEndUserRoutes();
  const urlQueryKey = UrlQueryObjectMap[urlObject];
  if (urlQueryKey) {
    return router.getUnwrappedQueryParam(urlQueryKey, null);
  }
  return null;
}

/**
 * A reference for identifying context objects.
 */
export interface ObjectRef {
  /**
   * The object type.
   */
  type: string;
  /**
   * The object identifier in the URL or null to represent no object.
   */
  objectId: string | null;
  /**
   * The object graphQL identifier (possibly encoded) or null to represent no object.
   */
  id: string | null;
}

/**
 * Gets a context object id from the current url.
 * @param urlObject The type of object to retrieve.
 * @return an ObjectId.  The id and objectId fields may be null if no object is found.
 */
export function useContextObjectRefFromUrl(urlObject: UrlObject): ObjectRef {
  const { router } = useEndUserRoutes();
  const objectParamId = useObjectKeyFromUrl(urlObject);
  const queryId = useQueryObjectKeyFromUrl(urlObject);
  const { type } = UrlObjectMap[urlObject];
  if (router.getCurrentPageName() === EndUserPages.PageEditorPage) {
    const objectId = queryId ? IdConverter.idToTuple(queryId).id : null;
    return {
      type,
      objectId,
      id: queryId ?? null
    };
  }
  const id = objectParamId ? `${type ? `${type}:` : ''}${objectParamId}` : null;
  return {
    type,
    objectId: objectParamId,
    id
  };
}

/**
 * Gets a context object from the current url.
 * @param urlObject The type of object to retrieve.
 * @param module The module
 * @param options the query options
 *
 * @author Adam Ayres
 */
export default function useContextObjectFromUrl<TData, TVariables extends IdBasedQuery>(
  module: NodeModule | string,
  urlObject: UrlObject,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const { id, type } = useContextObjectRefFromUrl(urlObject);
  const { query } = UrlObjectMap[urlObject];
  log.trace('Getting %s from url: $s', type || 'object', id);
  return useQueryWithTracing<TData, TVariables>(module, query, {
    ...options,
    skip: options?.skip || !id,
    variables: {
      id,
      ...options?.variables
    }
  });
}
