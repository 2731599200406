import React, { useRef } from 'react';
import type { OverlayChildren } from 'react-bootstrap/lib/esm/Overlay';
import { OverlayTrigger, Popover, useClassNameMapper } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useUIDSeed } from 'react-uid';
import IconTypes from '../../../public/static/graphics/processed/enums';
import { IconColor, IconSize } from '../Icon/enums';
import Icon from '../Icon/Icon';
import localStyle from './InfoPopover.module.pcss';
import type InfoPopoverProps from './InfoPopoverProps';
import Button from '../Button/Button';
import { ButtonVariant } from '../Button/enums';
import useTranslation from '../../useTranslation';
import { SharedComponent } from '../../../enums';
import { offsetPopperConfig } from '../../../helpers/ui/PopperJsHelper';
import type { CSSPropertiesWithVars } from '../../../helpers/styles/CSSPropertiesWithVarsHelper';

/**
 * Renders hoverable icon to display informative text (or renders whatever element is provided as 'info') related
 * to a form field.
 *
 * @author Willi Hyde, Stephen McLaughry, Rosalyn Rowe, Luisina Santos
 */
const InfoPopover: React.FC<React.PropsWithChildren<InfoPopoverProps>> = ({
  className,
  btnClassName,
  icon = IconTypes.InfoIcon,
  iconColor = IconColor.GRAY_900,
  iconSize = IconSize.PX_14,
  infoText,
  labelText
}) => {
  const cx = useClassNameMapper(localStyle);
  const uidSeed = useUIDSeed();
  const i18n = useTranslation(SharedComponent.INFO_POPOVER);
  const { formatMessage, loading: textLoading } = i18n;

  /**
   * LIA-88388 By default tooltips, which use `role="tooltip"`, render in the body element.
   * When a tooltip is triggered from a modal, which use `aria-modal="true"`, contents that
   * live outside the modal, such as a tooltip in the body, are not read by screen readers.
   * We can solve this problem by rendering the tooltip inside the modal,
   * guaranteeing that the tooltip element will be contained within the modal and thus read
   * by the screen reader.
   */
  const buttonRef = useRef<HTMLButtonElement>();

  if (textLoading) {
    return null;
  }

  const overlay: OverlayChildren = (
    <Popover id={uidSeed('info-popover')}>
      <Popover.Content className={cx('lia-info-text')}>
        <FormattedMessage
          id={uidSeed('info-popover-text')}
          defaultMessage={infoText}
          values={{
            br: <br />,
            bold: (...chunks) => (<strong>{chunks}</strong>) as React.ReactNode
          }}
        />
      </Popover.Content>
    </Popover>
  );

  const iconStyles: CSSPropertiesWithVars = {
    '--lia-local-icon-color': `var(${iconColor})`
  };

  return (
    <>
      <OverlayTrigger
        placement="auto"
        popperConfig={offsetPopperConfig(0, 20, true)}
        overlay={overlay}
        container={() =>
          (buttonRef?.current?.closest('[role=dialog]') as HTMLElement) ?? document.body
        }
      >
        <Button
          variant={ButtonVariant.UNSTYLED}
          aria-label={formatMessage('moreInformation')}
          className={cx('lia-info-btn', { 'lia-with-label': labelText }, btnClassName)}
          testId="InfoPopover"
          ref={buttonRef}
        >
          <Icon icon={icon} size={iconSize} color={iconColor} className={cx(className)} />
          {labelText && (
            <span style={iconStyles} className={cx('lia-label')}>
              {labelText}
            </span>
          )}
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default InfoPopover;
